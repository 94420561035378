// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brand-js": () => import("../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-class-master-js": () => import("../src/pages/class/master.js" /* webpackChunkName: "component---src-pages-class-master-js" */),
  "component---src-pages-class-normal-js": () => import("../src/pages/class/normal.js" /* webpackChunkName: "component---src-pages-class-normal-js" */),
  "component---src-pages-education-js": () => import("../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schools-js": () => import("../src/pages/schools.js" /* webpackChunkName: "component---src-pages-schools-js" */),
  "component---src-pages-studio-js": () => import("../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */)
}

